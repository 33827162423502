<template>
  <div class="content">
    <div class="nav">
        <div class="logo">
            <span class="iconfont lh-icon-logo"></span>
        </div>
        <lh-nav-menu>
            <lh-menu-group>
                <div slot="groupName">书签导航</div>
                <lh-menu-item to="/">主页</lh-menu-item>
                <lh-menu-item to="/bookmarks">我的书签</lh-menu-item>
            </lh-menu-group>
            <lh-menu-group>
                <div slot="groupName">我的工具</div>
                <lh-menu-item to="/coding">coding</lh-menu-item>
                <lh-menu-item to="/test">测试实验</lh-menu-item>
            </lh-menu-group>
            <lh-menu-group>
                <div slot="groupName">后台管理</div>
                <lh-menu-item to="/favorites">收藏夹</lh-menu-item>
            </lh-menu-group>
        </lh-nav-menu>
    </div>
    <div class="view">
        <router-view/>
    </div>
  </div>
</template>

<script>
export default {
    name:"Index"
}
</script>

<style lang="less" scoped>
.content{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    .nav{
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo{
            padding: 10px;
            .lh-icon-logo{
                font-size: 100px;
            }
        }
        width: 15%;
        //border: solid;
        height: 100%;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    }
    .view{
        width:85%;
        height: 100vh;
        overflow: auto;
        //border: solid;
    }
}
</style>